import { useEffect } from 'react';
import { useAppDispatch } from '@store/Hooks';
import { openPaymentMethodForm, PaymentMethodFormType } from '@entities/current-user';
const useSubscriptionParams = () => {
    const dispatch = useAppDispatch();
    const checkURLParams = () => {
        const resultStatus = new URLSearchParams(window.location.search).get('result');
        switch (resultStatus) {
            case 'success':
                dispatch(openPaymentMethodForm({
                    type: PaymentMethodFormType.ChangeSubscriptionPlan,
                    defaultStep: 1,
                }));
                break;
            case 'error':
                dispatch(openPaymentMethodForm({
                    type: PaymentMethodFormType.ChangeSubscriptionPlan,
                    defaultStep: 2,
                }));
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        checkURLParams();
    }, []);
};
export default useSubscriptionParams;

import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
const COLORS = {
    IN_PROGRESS: {
        color: '#FFA01C',
        background: '#FFF4D5',
    },
    UNPAID: {
        color: '#E5484D',
        background: '#FFEFEF',
    },
    PAID: {
        color: '#30A46C',
        background: '#E9F9EE',
    },
};
const StatusBadge = styled.span(props => ({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    gap: '4px',
    borderRadius: `${props.theme.shape.borderRadius}px`,
    color: COLORS[props.status].color,
    background: COLORS[props.status].background,
    fontWeight: 500,
}));
const PaymentStatus = ({ cellParams }) => {
    const { t } = useTranslation();
    return (_jsx(StatusBadge, { color: 'warning', status: cellParams.rowData.status, children: t(`settings.tabs.subscription.payment_table.statuses.${cellParams.rowData.status}`) }));
};
export default PaymentStatus;

import { PlatformSubscriptionTier } from '@shared/api/subscription';
import { PaymentStatusEnum, } from '../model';
const PAYMENTS_MOCK_DATA = [
    {
        date: new Date('2022-06-29'),
        total: 99,
        currency: '$',
        platformFee: 9,
        subscriptionPlan: PlatformSubscriptionTier.Advanced,
        status: PaymentStatusEnum.InProgress,
    },
    {
        date: new Date('2022-05-29'),
        total: 99,
        currency: '$',
        platformFee: 9,
        subscriptionPlan: PlatformSubscriptionTier.Starter,
        status: PaymentStatusEnum.UnPaid,
    },
    {
        date: new Date('2022-04-29'),
        total: 99,
        currency: '$',
        platformFee: 9,
        subscriptionPlan: PlatformSubscriptionTier.Starter,
        status: PaymentStatusEnum.Paid,
    },
];
const PAYMENT_INFO_MOCK = {
    paymentDate: new Date('July 29, 2023'),
    paymentAmount: 99,
    paymentCurrency: '$',
    paymentPlan: PlatformSubscriptionTier.Advanced,
};
export { PAYMENTS_MOCK_DATA, PAYMENT_INFO_MOCK, };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Block from '@shared/ui/settings/SettingsBlockContainer';
import { PAYMENT_INFO_MOCK, PAYMENTS_MOCK_DATA } from '@pages/settings-subscription/lib/constants';
import Table from '@shared/ui/display/Table';
import tableRendererWrapper from '@shared/ui/display/Table/tableRendererWrapper';
import SubscriptionRowHeader from './components/SubscriptionRowHeader';
import ActionButtonCell from './components/ActionButtonCell';
import SubscriptionPlanCell from './components/SubscriptionPlanCell';
import PaymentStatus from './components/PaymentStatus';
import PaymentInfo from './components/PaymentInfo';
import DateCell from './components/DateCell';
import AmountCell from './components/AmountCell';
const getPaymentColumnsDefinition = (t) => ([
    {
        header: t('settings.tabs.subscription.payment_table.date'),
        accessorKey: 'date',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        aggregationFn: 'count',
        cell: tableRendererWrapper(DateCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.total'),
        accessorKey: 'total',
        enableSorting: false,
        meta: {
            flex: 1,
            align: 'center',
        },
        cell: tableRendererWrapper(AmountCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.platform_fee'),
        accessorKey: 'platformFee',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(AmountCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.status'),
        accessorKey: 'status',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(PaymentStatus),
    },
    {
        header: t('settings.tabs.subscription.payment_table.subscription_plan'),
        accessorKey: 'subscriptionPlan',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(SubscriptionPlanCell),
    },
    {
        header: '',
        accessorKey: 'action_btn',
        enableSorting: false,
        meta: {
            flex: 1,
            align: 'right',
        },
        cell: tableRendererWrapper(ActionButtonCell),
    },
]);
const PaymentInformation = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const paymentColumnDefintion = useMemo(() => getPaymentColumnsDefinition(t), [t]);
    return (_jsxs(Block, { title: t('settings.tabs.subscription.payment_table.title'), gap: theme.spacing_sizes.m * 3, padding: theme.spacing_sizes.s * 5, children: [_jsx(PaymentInfo, Object.assign({}, PAYMENT_INFO_MOCK)), _jsx(Table, { columnDef: paymentColumnDefintion, data: PAYMENTS_MOCK_DATA, components: {
                    HeaderRow: SubscriptionRowHeader,
                }, className: '' })] }));
};
export default PaymentInformation;

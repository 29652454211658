import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { TableHeaderRow } from '@shared/ui/display/Table';
const TableHeaderRowStyled = styled((TableHeaderRow))(props => ({
    height: 'unset',
    '& .MuiTableCell-root': {
        padding: `0 ${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.l}px`,
    },
    '& .MuiTypography-root': {
        lineHeight: '17px',
    },
}));
const SubscriptionRowHeader = (props) => _jsx(TableHeaderRowStyled, Object.assign({}, props));
export default SubscriptionRowHeader;

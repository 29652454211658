import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodySemiBold, BodySmall } from '@components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
const PaymentInfoContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    justifyContent: 'space-between',
    padding: `${props.theme.spacing_sizes.xs * 1.25}px 0`,
}));
const InfoItemHeader = styled(BodySmall)(() => ({
    fontSize: 14,
    lineHeight: '17px',
}));
const InfoItemValue = styled(BodySemiBold)(() => ({
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '29px',
    letterSpacing: '-0.144px',
}));
const InfoItemContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'align' && propName !== 'justify' })(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
    alignItems: props.align || 'unset',
    justifyContent: props.justify || 'unset',
}));
const PaymentInfoItem = ({ title, value }) => (_jsxs(InfoItemContainer, { children: [_jsx(InfoItemHeader, { children: title }), _jsx(InfoItemValue, { children: value })] }));
const PaymentInfo = ({ paymentDate, paymentAmount, paymentCurrency, paymentPlan, }) => {
    const { t } = useTranslation();
    return (_jsxs(PaymentInfoContainer, { children: [_jsx(PaymentInfoItem, { title: t('settings.tabs.subscription.next_payment_date'), value: format(paymentDate, 'dd MMMM yyyy') }), _jsx(PaymentInfoItem, { title: t('settings.tabs.subscription.next_payment_total'), value: `${paymentCurrency} ${paymentAmount}` }), _jsx(PaymentInfoItem, { title: t('settings.tabs.subscription.current_plan'), value: paymentPlan }), _jsx(InfoItemContainer, { align: 'end', justify: 'end', children: _jsx(Button, { variant: 'plain', color: 'primary', size: 'small', children: t('settings.tabs.subscription.change_plan_btn') }) })] }));
};
export default PaymentInfo;
